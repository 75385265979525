// Section Background
.section {
  position: relative;

  .section-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}

/* Welcome Section */
.section-welcome {
  padding-top: 30px !important;
  padding-bottom: 55px !important;

  .btn-wrap {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Welcome Personal Section */
.section-welcome-personal {
  padding: 60px 0;

  // Position Right
  .row.position-right {
    flex-direction: row-reverse;

    .col-image {
      padding-left: 30px;
    }
  }

  // Position Left
  .row.position-left .col-image {
    padding-right: 30px;
  }

  .col-image img {
    max-width: 100%;
    display: block;
  }

  .content {
    margin-top: 40px;
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.4;
  }

  .btn-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }

  @media (max-width: 991px) {
    .row {
      flex-direction: column !important;
    }

    .row .col-image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px !important;
    }

    .row .col-content {
      padding-top: 35px;
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// About Section
.section-about {
  position: relative;
  width: 100%;
  padding: 125px 0;

  .container {
    position: relative;
    z-index: 15;
    color: #fff;
  }

  .row {
    justify-content: center;
  }


  // Title
  .section-about-title {
    margin-bottom: 20px;
    margin-top: 0;
  }

  .content {
    max-width: 100%;
    width: 100%;

    p {
      max-width: 100%;
      white-space: normal;
      word-break: break-word;
    }
  }

  .col-image {

    &.image-circle {
      position: relative;

      img {
        border-radius: 100%;
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-width: 240px;
        max-height: 240px;
        object-position: top;
      }
    }

    &.image-oval {
      img {
        border-radius: 100%;
      }
    }

    img {
      max-width: 100%;
      display: block;
    }
  }

  .btn-wrap {
    margin-top: 10px;
    display: flex;
  }


  @media (max-width: 767px) {
    padding: 70px 0;

    .col-image img {
      max-width: 260px;
      margin-left: auto;
      margin-right: auto;
    }

    .col-content {
      margin-top: 35px;
      margin-left: auto;
      margin-right: auto;
      max-width: 480px;
      text-align: center;
    }

    .col-content {
      .btn-wrap {
        display: flex;
        justify-content: center;
      }
    }
  }
}

// About Box Section
.section-about-box {
  position: relative;
  width: 100%;
  padding: 160px 0;

  .container {
    padding: 10px;
    position: relative;
    z-index: 15;
  }

  .row {
    padding: 40px;
    margin: 0;
  }

  .section-about-title {
    margin-bottom: 20px;
    margin-top: 0;
  }

  .content {
    max-width: 100%;
    width: 100%;

    p {
      max-width: 100%;
      white-space: normal;
      word-break: break-word;
    }
  }

  .col-image {
    padding: 0;

    img {
      max-width: 100%;
      display: block;
    }
  }

  .col-content {
    padding: 0;
    padding-left: 40px;
    font-size: 18px;
    line-height: 1.5;
  }

  .btn-wrap {
    margin-top: 12px;
    display: flex;
  }

  @media (max-width: 767px) {
    padding: 70px 0;

    .row {
      padding: 35px 20px;
    }

    .col-image img {
      max-width: 260px;
      margin-left: auto;
      margin-right: auto;
    }

    .col-content {
      margin-top: 35px;
      margin-left: auto;
      margin-right: auto;
      max-width: 480px;
      text-align: center;
      padding: 0;

      .btn-wrap {
        display: flex;
        justify-content: center;
      }
    }

  }
}

// Staff Slider
.section-staff-slider {
  margin: 0 auto;
  padding: 60px 0;
  position: relative;

  .staff-slider-data {
    @media (min-width: 1024px) {
      &[data-arrows-dekstop='off'] {
        .owl-nav {
          display: none;
        }
      }
    }
    @media (max-width: 1023px) {
      &[data-arrows-mobile='off'] {
        .owl-nav {
          display: none;
        }
      }
    }
  }

  .about-me {
    max-width: 1360px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 15;

    .owl-prev, .owl-next {
      position: absolute;
      top: 50%;
      margin-top: -20px;
      font-size: 0;
      width: 40px;
      height: 40px;
      text-align: center;
      cursor: pointer;

      &:before {
        color: #486577;
        font-size: 30px;
      }
    }

    .owl-prev {
      left: 20px;

      &:before {
        content: '\f053';
        font-family: 'fontAwesome';
      }
    }

    .owl-next {
      right: 20px;

      &:before {
        content: '\f054';
        font-family: 'fontAwesome';
      }
    }

    .owl-nav {
      &[disabled] {
        display: none;

        .disabled {
          display: none;
        }
      }
    }

    .owl-carousel {
      .owl-stage {
        display: flex;
      }

    }

  }

  .staff-section-title {
    text-align: center;
    display: block;
  }

  .content {
    width: 1200px;
    max-width: calc(100% - 100px);
    margin: 0 auto;
    margin-top: 40px;
    overflow-x: hidden;
  }

  .staff-box {
    .wpforms-submit {
      display: inline-block;
    }

    .photo {
      display: block;
      width: 80%;
      position: relative;
      margin: 0 auto 20px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      max-width: 250px;
      text-align: center;
      font-size: 19px;
      font-weight: 300;
      line-height: 1.4;

      &:after {
        display: block;
        content: "";
        padding-bottom: 100%;
      }

    }

    .staff-title {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 20px;
      display: block;
    }

    p {
      text-align: center;
      margin-bottom: 15px;
    }

    .btn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px;

    }

  }

  .staff-container.loading {
    display: flex;
    justify-content: center;

    .staff-box {
      width: calc(100% / 3);
    }
  }

  @media (max-width: 991px) {
    padding: 35px 0;
  }
  @media (max-width: 767px) {
    .about-me .owl-prev {
      left: 3px;
    }

    .about-me .owl-next {
      right: 3px;
    }
  }
}

// Masonry Grid
.grid-section {
  background-color: #F3F3F3;
  padding: 60px 0;

  .container {
    z-index: 10;
    position: relative;
  }

  .section-title {
    margin-bottom: 20px;
    padding: 0 15px;
    display: block;
    text-align: center;
  }

  .grid-pages {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 30px);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0;

    .column {
      width: 50%;
    }

    .page-item-wrap {
      width: 100%;
      position: relative;
      overflow: hidden;

      &.square:after {
        content: "";
        padding-bottom: 85%;
        display: block;
      }

      &.rect:after {
        content: "";
        padding-bottom: 55%;
        display: block;
      }

      .page-item {
        width: 100%;
        position: absolute;
        height: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        overflow: hidden;

        .image-overlay {
          width: 100%;
          position: absolute;
          object-fit: cover;
          object-position: center;
          font-family: 'object-fit: cover; object-position: center;';
          height: 100%;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: 4;
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          transition: 0.3s all ease;
          z-index: 5;
        }

        .content {
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 6;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          transition: 0.3s all ease;
          padding-top: 58px;

          .page-title {
            display: block;
            line-height: 1.15;
            text-align: center;
            color: #fff;
            transition: 0.3s all ease;
            padding-left: 15px;
            padding-right: 15px;
            max-width: 95%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
          }

          .other-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transition: 0.3s all ease;
            opacity: 0;
            min-height: 110px;
            width: 100%;
          }

          .page-description {
            margin-bottom: 15px;
            display: block;
            text-align: center;
            color: #fff;
            transition: 0.3s all ease;
            padding-left: 15px;
            padding-right: 15px;
            max-width: 90%;
            margin-left: auto;
            margin-right: auto;
          }

          .btn {
            transition: 0.3s all ease;
            cursor: pointer;
          }
        }

        &:hover {
          .overlay {
            opacity: 1;
          }

          .content {
            padding-top: 0;

            .other-content {
              opacity: 1;
            }
          }

        }
      }


    }
  }

  @media (max-width: 767px) {
    .grid-pages {
      .column {
        width: 100%;
      }
    }
    .grid-pages {
      .page-item-wrap {
        &.square:after {
          padding-bottom: 100%;
        }

        &.rect:after {
          padding-bottom: 100%;
        }

        .page-item {
          .content {
            .page-title {
              font-size: 35px;
            }

            .page-description {
              font-size: 16px;
            }
          }

        }
      }
    }
    .section-title {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.2;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// Service Section
.home-services-bg {
  .home-services {
    padding: 60px 0;
    max-width: 1024px;
    margin: auto;
    width: 100%;
    position: relative;
    z-index: 10;

    .home-services__title {
      margin: 0;
      padding-bottom: 40px;
      text-align: center;
    }

    .services-wrap {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;

      .service-box {
        margin-bottom: 20px;
        text-align: center;
        box-sizing: border-box;

        .service-title {
          border-bottom: 1px solid #2ab8a9;
          line-height: 38px;
          padding-bottom: 15px;
          margin-bottom: 20px;
          font-weight: 300;
          margin-top: 0;

          &.no-border {
            border: none !important;
          }
        }

        p {
          text-align: center;
        }

        .service-thumb {
          margin-bottom: 10px;
          min-height: 235px;
          padding-top: 20px;
          transition: all 0.4s ease;
          background-position: bottom center;

          &:hover {
            min-height: 235px;
            padding-top: 0px;
            background: url(../img/shadow.png) no-repeat bottom center;
          }

          img {
            border-radius: 100%;
            margin: auto;
            height: 180px !important;
            width: 180px !important;
            object-fit: cover;

            &.square {
              border-radius: 0px;
            }

            &.rounded {
              border-radius: 25px;
            }
          }
        }
      }

      &[data-col="3"] {
        justify-content: center;

        .service-box {
          width: calc(100% / 3);
          padding: 0 35px;
        }
      }

      &[data-col="4"] {
        .service-box {
          width: calc(100% / 4);
          padding: 0 35px;
        }
      }

      &[data-col="5"] {
        .service-box {
          width: calc(100% / 5);
          padding: 0 20px;
        }
      }
    }

    @media (max-width: 991px) {
      .services-wrap .service-box {
        width: calc(100% / 2) !important;
        padding: 0 35px !important;
      }

      .service-thumb {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
      }

      .service-content {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (max-width: 767px) {

      .home-services h2 {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.2;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }

    @media (max-width: 565px) {
      .services-wrap .service-box {
        width: 100% !important;
        padding: 0 35px !important;
      }
    }
  }
}

// Call To Action
.call-to-action {
  display: flex;
  padding: 15px 0;

  .row {
    align-items: center;
    position: relative;
    z-index: 10;
  }

  .col-title {
    padding: 25px 15px;
  }

  .col-btn {
    display: flex;
    justify-content: flex-end;
    padding: 25px 15px;
  }

  @media (max-width: 991px) {
    padding: 60px 0;
    .col-title {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-bottom: 0;

      .cta-title {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        margin-top: 0;
      }
    }
    .col-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      .content-btn {
        min-width: 140px;
      }
    }
  }
}

// Call To Action - Content
.call-to-action-content {
  padding: 60px 0;

  .container {
    position: relative;
    z-index: 10;
  }
}

// Info Boxes Section
.info-boxes-section {
  padding-top: 160px;
  padding-bottom: 130px;

  .box-item__wrap {
    margin-bottom: 30px;
  }

  .box-item {
    padding: 10px;

    .box-item__header {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .box-item__title {
      text-align: center;
      margin: 0 auto;
      text-transform: none;
      color: #2a639d;
      display: block;
      max-width: 100%;
      white-space: normal;
      word-break: break-word;
    }

    .box-item__body {
      padding: 25px;

      &, p, ul li {
        text-align: center;
      }

      p {
        margin-top: 0;
      }

      ul, ol {
        margin-top: 0;
        padding-left: 0;
        margin-left: 0;
        list-style-position: inside;
      }

      h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        display: block;
      }
    }
  }

  .container {
    position: relative;
    z-index: 15;
  }

  @media (max-width: 991px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

// Service Section Spaced
.service-section-spaced {
  padding: 80px 0;

  .content-wrap {
    position: relative;
    z-index: 15;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 1600px;
    max-width: calc(100% - 60px);

    .service-section-spaced__title {
      background-color: transparent;
      display: block;
      text-align: center;
      margin-top: 0;
      margin-bottom: 40px;
    }
  }

  .service-box-wrap {
    margin-bottom: 30px;
  }

  .service-box {
    width: 100%;
    position: relative;
    height: 270px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  .service-box-content {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;

    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transition: 0.2s all ease;
      background-color: #000;
      opacity: 0.15;
      z-index: 10;
    }

    &:hover:after {
      opacity: 0.4;
    }
  }

  .service-title {
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    position: relative;
    z-index: 15;
  }

  @media (max-width: 767px) {
    .content-wrap {
      max-width: calc(100% - 30px);
    }
  }
}

// Posts Slideshow
.posts-slideshow-section {
  padding-top: 65px;
  padding-bottom: 75px;

  .container {
    position: relative;
    z-index: 15;
  }

  .block-title {
    text-align: center;
    display: block;
    margin: 0;
  }

  .posts-slider-wrapper {
    margin-top: 60px;
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  // Arrows
  .prev-arrow {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateX(-85px) translateY(-50%);
    z-index: 10;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 30px;
      width: auto;
      display: block;
    }
  }

  .next-arrow {
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateX(85px) translateY(-50%);
    z-index: 10;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 30px;
      width: auto;
      display: block;
      transform: rotate(180deg);
    }
  }

  .slider-arrow svg path {
    fill: #1E73BE;
    @include transition;
  }

  // Slide
  .slide-post {
    position: relative;

    .post-title {
      margin-top: 15px;
      margin-bottom: 10px;
      display: block;

      a {
        color: inherit;
      }
    }

    .post-thumbnail {
      display: block;
      position: relative;

      &:after {
        display: block;
        content: "";
        padding-bottom: 56.65%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }

    .post-excerpt {
      &, p {
        font-size: 16px;
        display: block;
        margin-top: 0;
      }
    }

    .btn-wrap {
      display: flex;
      align-items: center;
      margin-top: 15px;
    }

    .post-meta {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 8px;
      margin-top: 15px;

      &, p {
        font-size: 15px;
      }

      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        margin-right: 6px;
      }

      .divider {
        margin-left: 6px;
      }
    }
  }

  @media (max-width: 1100px) {
    .posts-slider-wrapper {
      margin-top: 40px;
    }

    .prev-arrow {
      transform: translateX(calc(-100% - 15px)) translateY(-50%);
    }

    .next-arrow {
      transform: translateX(calc(100% + 15px)) translateY(-50%);
    }
  }
  @media (max-width: 991px) {
    .posts-slider-wrapper {
      padding-left: 25px;
      padding-right: 25px;
    }

    .slider-arrow svg {
      height: 25px;
    }

    .prev-arrow {
      transform: translateX(calc(-50%)) translateY(-50%);
    }

    .next-arrow {
      transform: translateX(calc(50%)) translateY(-50%);
    }
  }
}

// Image Info
.image_info_section {
  padding-top: 90px;
  padding-bottom: 40px;

  .image_info_section__title {
    margin-top: 0;
    text-align: center;
    display: block;
  }

  .container {
    position: relative;
    z-index: 15;
  }

  .service-info-row {
    margin-top: 40px;
  }

  .service-info-box-wrap {
    margin-bottom: 60px;

    .service-info-box {
      width: 100%;
      max-width: 100%;

      .service-info-box__title {
        text-align: center;
        display: block;
        margin: 22px 0;
      }

      .service-info-box__description {
        display: block;
        text-align: center;
      }

      .thumbnail-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;

        &.circle {
          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 80%;
            height: 100%;
            border-radius: 100%;
            object-fit: cover;
            overflow: hidden;
            display: block;
            margin: auto;
          }
        }

        &.rect {
          img {
            position: relative;
            width: 100%;
            height: 200px;
            object-fit: cover;
          }
        }

        &.circle {
          &:after {
            padding-bottom: 80%;
            display: block;
            content: "";
          }
        }

      }

      .btn-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
      }
    }
  }
}

// Image To Text & Text to Image Sections
.flexible-boxes-section {
  padding-top: 60px;
  padding-bottom: 60px;

  .container {
    position: relative;
    z-index: 10;
  }

  .section-title {
    display: block;
    text-align: center;
  }

  .flexible-boxes {
    margin-top: 60px;

    .flexible-box {
      margin: 0;
      display: flex !important;
      width: 100% !important;
      justify-content: space-between !important;
      position: relative;
      vertical-align: top;
      padding: 0 15px;
      font-size: 19px;
      font-weight: 300;
      line-height: 1.4;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &.row-reverse {
        flex-direction: row-reverse;
      }

      .col-image {
        max-width: 40%;
        width: 100%;
        position: relative;
        height: 100%;
        padding: 15px;
        display: flex;
        flex-flow: column;
      }

      .col-content {
        max-width: 60%;
        display: flex;
        flex-flow: column;
        padding: 15px;
        position: relative;
        width: 100%;
        text-align: left !important;
      }

      .flexible-img {
        min-height: 230px;
        background-size: cover;
        background-position: center;
        margin-bottom: 15px;
        width: 100%;
        display: block;
        position: relative;
        height: 300px;
        object-fit: cover;
      }

      .flexible-box__title {
        margin-top: 0;
        margin-bottom: 20px;
      }

      .btn-wrap {
        display: flex;
        margin-top: 25px;
      }
    }
  }

  @media (max-width: 991px) {
    .flexible-boxes {
      .flexible-box {
        margin-bottom: 60px;

        &:last-child {
          margin-bottom: 0;
        }

        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        text-align: center;

        .col-image {
          height: auto;
          max-height: unset;
          min-height: unset;
          width: 100%;
          position: relative;
          padding: 0;
          max-width: 420px;
          margin-left: auto;
          margin-right: auto;

          &:after {
            padding-bottom: 80%;
            display: block;
            content: "";
          }

          .flexible-img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            min-height: 100%;
            max-height: 100%;
            object-fit: cover;
            background-size: cover;
          }
        }

        .col-content {
          width: 100%;
          max-width: 420px;
          margin-left: auto;
          margin-right: auto;
          padding-top: 25px;

          .flexible-box__title {
            text-align: center;
          }

          .flexible-box__content {
            text-align: center;

            &, p {
              font-size: 17px;
            }
          }

          .btn-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }


      }
    }
  }

}

// Map Section
.map-section {
  padding-top: 70px;
  padding-bottom: 110px;
  background-color: #fff;

  &.map-section-wide {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .section-title {
    text-align: center;
    margin: 0;
    padding-bottom: 40px;
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .maps-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: -10px;

    .map {
      flex-grow: 1;
      flex-basis: calc(100% / 3 - 20px);
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      max-height: 460px;
      overflow: hidden;

      &:after {
        display: block;
        content: "";
        padding-bottom: 60%;
        max-height: 600px;
      }

      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
      }

      iframe.greyscale {
        filter: grayscale(100%);
      }

      iframe.sepia {
        filter: sepia(100%);
      }

      iframe.colorful {
        filter: hue-rotate(75deg);
      }

      iframe.nighttime {
        filter: invert(90%);
      }

      .google-map-wrap {
        width: 100%;
        height: 100%;

        .google-map {
          width: 100%;
          height: 100%;
        }
      }

    }
  }

  @media (max-width: 1199px) {
    .maps-wrap {
      margin-left: auto;
      margin-right: auto;

      .map {
        min-width: auto;
        width: 100%;
        margin-bottom: 0px;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  @media (max-width: 767px) {
    .section-title {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.2;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.page-template-home-page-php .map-section.no-gap {
  padding-top: 0 !important;
}

.page-template-home-page-php .map-section.no-gap .maps-wrap .map {
  margin-top: 0;
}








