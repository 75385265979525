.blog-template {
  background: $color-white;
  padding: 50px 0;

  .posts-list-wrap {
    margin-top: 35px;
  }

  .pagination-wrap {
    margin-top: 35px;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 14px;

    .first-page {
      margin-right: 10px;
    }

    .last-page {
      margin-left: 10px;
    }

    .nav-links {
      display: flex;
      align-items: center;

      span, .current {
        cursor: default;
      }

      .page-numbers {
        padding: 0 3px;
        font-size: 14px;
        min-width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 6px;
        @include transition;

        &.prev, &.next {
          padding-bottom: 2px;
        }

        &:hover, &.current {
          background-color: $color-blue;
          color: #fff;
        }

      }
    }

    &[data-shape=circle] {
      .nav-links {
        .page-numbers {
          border-radius: 100%;
        }
      }
    }

    @media (max-width: 565px) {
      .first-page {

        margin-right: 6px;
      }
      .last-page {
        margin-left: 6px;
      }
      .nav-links {
        min-width: 26px;
        height: 26px;

        .page-numbers {
          margin: 0 2px;
        }
      }
    }
  }

  .posts-list {
    width: 100%;

    .post-wrap {
      width: 100%;
      display: flex;
    }
  }

}

// Post Left Classic
.post-classic-left {
  margin-bottom: 55px;
  display: flex;
  width: 100%;
  .thumbnail-wrap {
    width: 320px;
    min-width: 320px;
    display: block;
    position: relative;

    &:after {
      display: block;
      content: "";
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .post-info {
    padding-left: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    &__meta {
      min-height: 60px;
    }

    .post-meta {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &, p {
        font-size: 16px;
      }

      color: $color-grey;
      line-height: 1;

      a {
        display: block;
        @include transition;
      }

      .post-meta-item__author {
        margin-bottom: 10px;
      }

      .post-meta-item__cats {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;

        a {
          border-left: 1px solid $color-light-grey;
          padding-left: 10px;
          margin-left: 10px;
        }
      }

      .post-meta-item__tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;

        a {
          border-left: 1px solid $color-light-grey;
          padding-left: 10px;
          margin-left: 10px;

          &:last-child {
            border-right: 0;
            padding-right: 0;
            margin-right: 0;
          }
        }
      }
    }

    .post-date {
      margin-top: 5px;
      font-size: 14px;
      color: rgb(149, 149, 149);
      line-height: 1.714;
    }

    .post-title {
      display: block;
      margin-bottom: 10px;
    }

    .post-excerpt {
      &, p {
        font-size: 18px;
      }
    }

    .post-permalink {
      margin-top: 25px;
      min-width: 150px;
    }

  }

  &[data-thumbnail='off'] {
    .post-info {
      padding-left: 0;
    }
  }


  @media (max-width: 767px) {
    margin-bottom: 60px;
    flex-direction: column;

    .thumbnail-wrap {
      width: 100%;

      &:after {
        padding-bottom: 56.25%;
      }
    }
    .post-info {
      padding-top: 25px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// Post Left Classic
.post-classic-top {
  width: 100%;
  margin-bottom: 60px;

  .thumbnail-wrap {
    width: 100%;
    display: block;
    position: relative;
    max-height: 300px;
    overflow: hidden;

    &:after {
      display: block;
      content: "";
      padding-bottom: 56.25%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: auto;
    }
  }

  .post-info {
    padding-top: 25px;

    .post-meta {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &, p {
        font-size: 16px;
      }

      color: $color-grey;
      line-height: 1;

      a {
        display: block;
        @include transition;
      }

      .post-meta-item__author {
        margin-bottom: 10px;
      }

      .post-meta-item__cats {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;

        a {
          display: flex;
          padding-left: 10px;
          margin-left: 10px;
          border-left: 1px solid $color-light-grey;
        }
      }

      .post-meta-item__tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;

        a {
          padding-left: 10px;
          margin-left: 10px;
          border-left: 1px solid $color-light-grey;

          &:last-child {
            border-right: 0;
            padding-right: 0;
            margin-right: 0;
          }
        }
      }
    }

    .post-date {
      font-size: 14px;
      color: rgb(149, 149, 149);
      line-height: 1.714;
      margin-bottom: 25px;
      display: block;
    }

    .post-title {
      display: block;
      margin-bottom: 20px;
    }

    .post-excerpt {
      &, p {
        font-size: 14px;
        color: $color-grey;
        line-height: 1.714;
      }
    }

    .post-permalink {
      margin-top: 30px;
      min-width: 150px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

  }
}

// Post Gridded
.post-gridded {
  width: 100%;
  background-color: #f4f4f4;
  margin-bottom: 30px;

  .thumbnail-wrap {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      padding-bottom: 100%;
      display: block;
      content: "";
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 10;
    }

    .thumbnail-label {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 15;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: #fff;
      text-align: center;
      @include transition;
      background-color: rgba(0, 0, 0, .3);
      opacity: 0;
    }

    &:hover {
      .thumbnail-label {
        opacity: 1;
      }
    }
  }

  .post-info {
    display: block;
    width: 100%;
    padding: 28px;
    text-align: center;

    .post-date {
      display: block;
      text-align: center;
      font-size: 14px;
      color: rgb(104, 104, 104);
      line-height: 0.203;
    }

    .post-title {
      text-align: center;
      margin: 20px 0;
      font-size: 18px;
      min-height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        @include transition;
      }
    }

    .post-meta {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;

      &, p {
        font-size: 14px;
      }

      color: $color-grey;
      line-height: 1;

      a {
        display: block;
        @include transition;
      }

      .post-meta-item__cats {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;

        a {
          border-right: 1px solid $color-light-grey;
          padding-right: 10px;
          margin-right: 10px;
        }
      }

      .post-meta-item__tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;

        a {
          border-right: 1px solid $color-light-grey;
          padding-right: 10px;
          margin-right: 10px;

          &:last-child {
            border-right: 0;
            padding-right: 0;
            margin-right: 0;
          }
        }
      }
    }
  }

  &[data-col="4"] {
    .post-info {
      padding: 25px 15px;

      .post-title {
        min-height: 60px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .post-meta {
        &, p {
          font-size: 12px;
        }
      }
    }
  }


}

// Post Single
.single-post-template {
  .content-col {
    .page-title {
      font-size: 40px;
    }

    .post-meta {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      color: $color-grey;
      line-height: 1;

      a {
        display: block;
        @include transition;
      }

      .post-meta-item__author {
        margin-bottom: 10px;
        display: flex;

        a {
          margin-left: 4px;
        }
      }

      .post-meta-item__cats {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;

        a {
          display: flex;
          padding-left: 10px;
          margin-left: 10px;
          border-left: 1px solid $color-light-grey;
        }
      }

      .post-meta-item__tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;

        a {
          padding-left: 10px;
          margin-left: 10px;
          border-left: 1px solid $color-light-grey;

          &:last-child {
            border-right: 0;
            padding-right: 0;
            margin-right: 0;
          }
        }
      }
    }


    .post-date {
      margin-top: 10px;
    }

    .post-thumbnail {
      float: left;
      max-width: 350px;
      margin-right: 50px;
      margin-bottom: 35px;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }


  &[data-layout=sidebar-content], &[data-layout=content-sidebar] {
    .content-col .post-thumbnail {
      width: 250px;
      margin-right: 30px;
      margin-bottom: 15px;
    }
  }
}

// Comments
.comments-wrap {
  padding-top: 30px;
  display: block;
  width: 100%;
  font-size: 16px;

  &.commented {
    border-top: 1px #CCCCCC solid;
  }

  .logged-in-as-wrapper {
    width: 100%;
  }

  .logged-in-as {
    margin-bottom: 25px;
    font-size: 16px;

    .logged-user {
      margin-left: 6px;
    }

    .logout-btn {
      margin-left: 10px;
      color: $color-brown;
    }
  }

  .comment-reply-title {
    color: $color-brown;
    font-style: normal;
  }

  .policy-field {
    font-size: 16px;
    display: block;
    margin-top: 20px;
    max-width: 65%;
  }

  // Form
  .comment-form-wrap {
    border-top: 1px solid #ccc;
    padding-top: 30px;
  }

  .comment-form {
    margin-top: 15px;

    .form-group {
      width: 100%;
      margin-bottom: 25px;

      input {
        border: 1px solid #8E8E8E;
        width: 100%;
        padding: 0 15px;
        height: 45px;
        color: #333;
        z-index: 16;
      }

      .form-label {
        margin-bottom: 4px;
        display: inline-flex;
        align-items: center;
      }
    }

    .textarea-comment {
      width: 100%;
      border: 1px solid #8E8E8E;
      resize: vertical;
      padding: 15px;
      min-height: 100px;
      max-height: 300px;
      color: #333;
      z-index: 16;
    }

    .submit-btn {
      margin-top: 15px;
      min-width: 180px;
      text-align: center;
      justify-content: center;
    }
  }

  .comment {
    list-style: none;

    .comment-wrap {
      margin-bottom: 35px;

      .comment-header {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .comment-avatar {
          width: 70px;
          height: 70px;
        }

        .comment-info {
          padding-left: 15px;

          .author-title {
            font-weight: bold;
            font-size: 16px;
            display: inline-block;
            margin: 0;
          }

          .comment-date {
            margin-top: 5px;
            font-size: 14px;
            display: flex;
            align-items: center;

            .comment-reply-link {
              display: flex;
              align-items: center;

              .slash-divider {
                margin: 0 10px;
              }
            }
          }

          a {
            color: $color-brown;
          }
        }
      }

      .comment-body {
        .comment-text {
          font-size: 16px;

          p {
            font-size: 16px;
          }

          .wait {
            color: $color-brown;
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .logged-in-as-wrapper {
      justify-content: flex-start;
      margin-top: 0;
      margin-bottom: 25px;
    }
    .policy-field {
      max-width: 100%;
    }
    .logged-in-as {
      max-width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
    }
  }
}