.mobile-navigation, .mobile-navigation__overlay {
  display: none;
}

@media (max-width: 1024px) {
  .mobile-navigation__overlay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    width: 55%;
    min-height: 100vh;
    max-height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100000;
    overflow: hidden;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    -webkit-transform: translateX(101%);
    -ms-transform: translateX(101%);
    transform: translateX(101%);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mobile-navigation__overlay img {
    display: block;
    object-fit: contain;
    max-width: 70%;
    height: auto;
    margin: auto;
  }

  .mobile-navigation__overlay.active {
    transform: translateX(0);
  }

  .mobile-navigation {
    transform: translateX(-101%);
    display: block;
    position: fixed;
    width: 45%;
    min-height: 100vh;
    max-height: 100vh;
    background: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    overflow: hidden;
    transition: 0.3s all ease;
  }

  .mobile-navigation__scroller {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 90;
  }

  .mobile-navigation.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  .mobile-navigation__template {
    position: relative;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: block;
    overflow: hidden;
  }

  .mobile-navigation__template .container {
    width: 100%;
    max-width: calc(100% - 100px);
    margin-left: auto;
    margin-right: auto;
  }

  .mobile-navigation__header {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    height: 100px;
  }

  .mobile-navigation__header .container {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .mobile-navigation__header .close-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 20px;
  }

  .mobile-navigation__header .close-button svg {
    height: 18px;
    width: auto;
    display: block;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .mobile-navigation__header .close-label {
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    font-size: 17px;
    font-weight: 400;
  }

  .mobile-navigation__header .close-button polygon {
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
  }

  /* Menu */
  .mobile-navigation__menu {
    padding: 0;
  }

  .mobile-navigation__menu .menu {
    margin: 0 !important;
    padding: 25px 0;
    display: block;
    width: 100%;
    list-style-type: none;
  }

  .mobile-navigation__menu .menu ul {
    list-style-type: none;
    list-style: none;
  }

  .mobile-navigation__menu .menu > li {
    margin: 25px 0;
    line-height: 1.6;
    font-size: 18px;
    min-height: 20px;
    list-style-type: none;
    list-style: none;
  }

  .mobile-navigation__menu .menu li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
  }

  .mobile-navigation__menu .menu li a .sub-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    padding: 5px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
  }

  .mobile-navigation__menu .menu li a .sub-toggle i {
    font-size: 90%;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
  }

  .mobile-navigation__menu .menu li a .sub-toggle.active i {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .mobile-navigation__menu .menu li .sub-menu {
    display: none;
    padding-left: 25px;
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .mobile-navigation__menu .menu li .sub-menu li {
    margin: 10px 0;
  }

  .mobile-navigation__menu .menu li .sub-menu li a {
    font-size: 16px;
  }


  /* Footer */
  .mobile-navigation__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 100;
  }

  .mobile-navigation__footer .mobile-widget {
    padding: 12px 0;
    list-style-type: none;
  }

  .mobile-navigation__footer ul {
    list-style-type: none;
    padding: 0;
    list-style: none;
  }

  .mobile-navigation__footer ul li {
    list-style-type: none;
    list-style: none;
  }


  /* Other */
  .utility-bar {
    max-width: 100vw;
  }


  .mobile-navigation .menu-main-menu-container {
    display: block;
  }

  .utility-bar {
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-size: 15px;
  }

  .utility-bar .wrap-1 {
    height: 56px;
    position: relative;
  }

  .utility-bar .wrap-1 .utility-bar-left {
    height: 56px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  #mob-icon {
    display: block;
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .logo img {
    max-width: 220px;
  }
}
@media (min-width: 661px) {
  .mobile-navigation__header .mobile-nav-image {
    display: none;
  }
}
@media (max-width: 660px) {
  .mobile-navigation__menu .menu {
    padding-bottom: 40px;
  }

  .mobile-navigation {
    width: 100% !important;
  }

  .mobile-navigation__overlay {
    display: none !important;
  }

  .mobile-navigation__header.show-logo .mobile-nav-image {
    height: 100%;
    max-width: 65%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mobile-navigation__header.show-logo .mobile-nav-image img {
    max-width: 100%;
    height: 95%;
    display: block;
    -o-object-fit: contain;
    object-fit: contain;
  }

  /* Hide Label */
  .mobile-navigation__header.show-logo .close-label {
    display: none;
  }

  /* Hide Logo */
  .mobile-navigation__header.show-label .mobile-nav-image {
    display: none;
  }

}
@media (max-width: 350px) {
  .mobile-navigation__menu {
    padding: 15px 0;
  }

  .mobile-navigation__menu .menu > li {
    margin: 20px 0;
  }

  .mobile-navigation__template .container {
    max-width: calc(100% - 30px);
  }

  .mobile-navigation__header {
    height: 70px;
  }

  .mobile-navigation__footer {
    padding: 10px 0;
  }

  .mobile-navigation__menu .menu {
    padding: 0;
  }
}


/* Mobile Menu Orientation Change */
@media (max-width: 991px) and (orientation: landscape) {

  .mobile-navigation__footer {
    height: 0 !important;
    display: none !important;
  }

  .mobile-navigation__header {
    height: 70px;
  }

  .mobile-navigation__menu .menu {
    padding: 15px 0;
  }

}


