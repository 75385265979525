// Hide Carrets
.sub_menu_carrets_off {
  .genesis-nav-menu {
    .menu-item-has-children {
      & > a {
        &:after {
          display: none !important;
        }
      }
    }
  }
}

// Nav A
.desktop-navigation[data-style="a"] {
  z-index: 1001;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .3s ease;
  border-bottom: 3px solid transparent;

  .wrap {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    max-height: 80%;
    display: flex;
    align-items: center;

    * {
      max-height: 100%;
    }

    img {
      max-height: 80px;
      max-width: 320px;
      transform: translateX(-10px);
    }
  }

  .navbar {
    display: flex;
    align-items: center;

    ul {
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        padding: 15px 15px;
        display: inline;

        a {
          text-decoration: none;
        }
      }
    }

    .genesis-nav-menu {
      display: flex;
      align-items: center;
      clear: both;
      width: auto;
      text-align: center;
      margin: 0;

      .menu-item-has-children {
        position: relative;

        & > a {
          position: relative !important;

          &:after {
            padding-left: 8px;
            content: "\f0d7";
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        }

        & > .sub-menu {
          margin-top: 10px;

          .sub-menu {
            margin-top: 0;
          }
        }


        &:hover {
          & > .sub-menu {
            opacity: 1;
            pointer-events: all;
          }
        }

        .sub-menu {
          left: 0;
          opacity: 0;
          position: absolute;
          transition: opacity .4s ease-in-out;
          width: 220px;
          z-index: 99;
          padding: 0;
          padding-top: 5px;
          border: none;
          pointer-events: none;

          a {
            border-top: none;
            padding: 20px;
            position: relative;
            width: 200px;
          }

          li {
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;

            & > a {
              border: none;
              text-shadow: none;
              padding: 8px 10px !important;

              &:after {
                display: none;
              }
            }
          }

          .sub-menu {
            left: 100% !important;
            top: 100%;
            transform: translateY(calc(-38px - 5px));

            .menu-item {

            }
          }
        }
      }


      li {
        @include transition;
      }

      a {
        display: block;
        @include transition;
      }


      & > .first > a {
        padding-left: 0;
      }

      & > .last > a {
        padding-right: 0;
      }

      & > .right {
        float: right;
        list-style-type: none;
        padding: 30px 0;
      }

      & > .right > a {
        display: inline;
        padding: 0;
      }

      & > .rss > a {
        margin-left: 48px;
      }

      & > .search {
        padding: 10px 0 0;
      }
    }
  }

  .contact-nav-btn {
    margin-left: 20px;
  }

  .mobile-nav-toggle-btn {
    display: none;
  }

  @media (max-width: 1199px) {
    .logo {
      img {
        height: 65px;
      }
    }
  }
  @media (max-width: 1024px) {
    .navbar {
      display: none;
    }
    .mobile-nav-toggle-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition;

      .mobile-nav-toggle-bar {
        @include transition;
      }
    }
  }
}


// Nav B C
.desktop-navigation[data-style="b"], .desktop-navigation[data-style="c"] {
  border: 0 !important;
  position: absolute;
  z-index: 900;
  left: 0;
  right: 0;
  width: 100%;
  height: 50px;
  transition: 0.3s background-color ease, 0.3s backgroud, 0.3s border-bottom-color, 0.3s border-bottom-width;

  .navbar {
    height: 100%;
  }

  .mobile-navbar {
    display: none;
  }

  .nav-primary {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .menu {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > li {
        height: 100%;
        display: block;
        position: relative;
        flex-grow: 1;

        & > a {
          letter-spacing: 2px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 22px;
          position: relative;
          text-align: center;
        }

        &.menu-item-has-children {
          position: relative;

          & > a {
            &:after {
              padding-left: 8px;
              content: "\f0d7";
              display: inline-block;
              font: normal normal normal 14px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
          }

          &:hover {
            & > .sub-menu {
              opacity: 1;
              pointer-events: all;
            }
          }
        }

        .sub-menu {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          width: 220px;
          max-width: 220px;
          min-width: 100%;
          pointer-events: none;
          opacity: 0;
          transition: opacity .4s ease-in-out;

          .menu-item-has-children {
            position: relative;

            &:hover {
              & > .sub-menu {
                opacity: 1;
                pointer-events: all;
              }
            }
          }

          .sub-menu {
            transform: translateY(-38px);
            top: 100%;
            left: 100%;
          }

          li {
            a {
              display: block;
              padding: 8px 10px !important;
              text-align: center;
              @include transition;
            }
          }
        }

      }
    }
  }

  @media (max-width: 1024px) {
    height: 100px;
    .navbar {
      display: none;
    }
    .mobile-navbar {
      background-color: #f3f3f3;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;

      .mobile-nav-toggle-btn {
        .mobile-nav-toggle-bar {
          @include transition;
        }
      }

      .logo {
        img {
          height: 65px;
          max-height: 80px;
          max-width: 320px;
          transform: translateX(-10px);
        }
      }
    }
  }
}


// Nav B Header
.nav-header {
  &[ data-style="b"] {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #fefbfa;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    height: 254px;

    .wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .widget {
        margin-bottom: 20px;

        a {
          @include transition;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      img {
        max-height: 100px;
        display: block;
        object-fit: contain;
      }
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }
}


// Nav C Header
.nav-header {
  &[ data-style="c"] {
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    height: 140px;
    background-color: #fefbfa;
    padding: 20px 0;

    &__row {
      justify-content: space-between;
      align-items: center;
    }

    .header-left {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

    }

    .header-right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
    }

    .widget {
      font-size: 16px;

      p {
        font-size: 16px;
      }

      img {
        max-height: 85px;
        object-fit: contain;
        display: block;
        width: auto;
      }

      .btn {
        margin-bottom: 12px;
        font-size: 12px !important;
        padding: 10px 12px;
        margin-right: 6px;
      }
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }
}


// Nav E
.desktop-navigation[data-style="e"] {
  padding-top: 3px;
  z-index: 900;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s background-color ease, 0.3s backgroud, 0.3s border-bottom-color, 0.3s border-bottom-width;
  border-bottom: 3px solid transparent;

  .wrap {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    max-height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      object-fit: contain;
      max-height: 80px;
      max-width: 250px;
    }
  }

  .contact-nav-btn {
    margin-left: 20px;
  }

  .navbar {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .nav-left {
      width: 38%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .menu {
        & > li {
          &:first-child {
            margin-left: 0;
            padding-left: 0;
          }
        }
      }

    }

    .logo {
      width: 24%;
    }

    .nav-right {
      width: 38%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        padding: 15px;
        display: inline;

        a {
          text-decoration: none;
        }
      }
    }

    .genesis-nav-menu {
      display: flex;
      align-items: center;
      clear: both;
      width: auto;
      text-align: center;
      margin: 0;

      li {
        @include transition;
      }

      a {
        display: block;
        @include transition;
      }

      .menu-item {
        display: inline-block;
        text-align: left;

        &.menu-item-has-children {
          position: relative;

          & > a {
            &:after {
              padding-left: 8px;
              content: "\f0d7";
              display: inline-block;
              font: normal normal normal 14px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
          }

          & > .sub-menu {
            margin-top: 0;
          }
        }

        &:hover {
          & > .sub-menu {
            pointer-events: all;
            opacity: 1;
          }
        }

        .sub-menu {
          left: 0;
          opacity: 0;
          position: absolute;
          transition: opacity .4s ease-in-out;
          width: 220px;
          z-index: 99;
          padding: 0;
          padding-top: 5px;
          border: none;
          pointer-events: none;

          a {
            border-top: none;
            padding: 20px;
            position: relative;
            width: 200px;
          }

          li {
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;

            & > a {
              border: none;
              text-shadow: none;
              padding: 8px 10px !important;

              &:after {
                display: none;
              }
            }
          }

          .sub-menu {
            left: 100% !important;
            top: 100%;
            transform: translateY(calc(-38px - 5px));

            .menu-item {

            }
          }
        }

      }


      & > .first > a {
        padding-left: 0;
      }

      & > .last > a {
        padding-right: 0;
      }

      & > .right {
        float: right;
        list-style-type: none;
        padding: 30px 0;
      }

      & > .right > a {
        display: inline;
        padding: 0;
      }

      & > .rss > a {
        margin-left: 48px;
      }

      & > .search {
        padding: 10px 0 0;
      }
    }
  }

  .mobile-navbar {
    display: none;
  }

  @media (max-width: 1024px) {
    .navbar {
      display: none;
    }

    .mobile-navbar {
      width: 100%;
      background-color: #f3f3f3;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;

      .mobile-nav-toggle-btn i {
        color: $color-brown;
      }

      .logo {
        img {
          height: 65px;
          max-height: 80px;
          max-width: 320px;
          transform: translateX(-10px);
        }
      }
    }
  }

  &[data-menu-position="inside"] {
    .navbar {
      .nav-left {
        justify-content: flex-end;
      }

      .nav-right {
        justify-content: flex-start;
      }
    }
  }

}


// Nav D
.desktop-navigation[data-style="d"] {
  z-index: 900;
  left: 0;
  right: 0;
  width: 100%;
  height: 82px;
  transition: 0.3s background-color ease, 0.3s backgroud, 0.3s border-bottom-color, 0.3s border-bottom-width;
  border-top: 3px solid #2a639d;
  border-bottom: 3px solid #2a639d;

  .navbar {
    height: 100%;
  }

  .mobile-navbar {
    display: none;
  }

  .nav-primary {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .menu {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > li {
        height: 100%;
        display: block;
        position: relative;
        z-index: 100;

        & > a {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 1px;
          text-transform: capitalize;
          padding: 0 30px;
          @include transition;
        }

        & > .sub-menu {
          &:before {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            border-bottom-color: #000;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-width: 11px;
            margin-left: -11px;
          }
        }

        &.menu-item-has-children {
          position: relative;

          & > a {
            &:after {
              padding-left: 8px;
              content: "\f0d7";
              display: inline-block;
              font: normal normal normal 14px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
          }

          &:hover {
            & > .sub-menu {
              opacity: 1;
              pointer-events: all;
            }
          }
        }

        .sub-menu {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 220px;
          min-width: 100%;
          pointer-events: none;
          opacity: 0;
          transition: opacity .4s ease-in-out;

          .menu-item-has-children {
            position: relative;

            &:hover {
              & > .sub-menu {
                opacity: 1;
                pointer-events: all;
              }
            }
          }

          .sub-menu {
            left: 100%;
            top: 100%;
            transform: translateY(-51px);
          }

          li {
            a {
              @include transition;
              padding: 16px 20px;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 0px;
              text-align: left;
              text-transform: none;
              border-bottom: 1px solid #fff;
              display: block;
              line-height: 1.35;
            }
          }
        }

      }
    }
  }

  @media (max-width: 1024px) {
    height: 100px;
    .navbar {
      display: none;
    }
    .mobile-navbar {
      background-color: #f3f3f3;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 20px;
      padding-right: 20px;

      .mobile-nav-toggle-btn {
        .mobile-nav-toggle-bar {
          @include transition;
        }
      }
    }
  }
}











