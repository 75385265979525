.home-slider-wrapper {

  &[data-aspect-ratio="16/9"] {
    position: relative;

    &, &.slider-long, &.slider-short {
      height: auto !important;
      max-height: unset !important;
      min-height: unset!important;
    }

    &:after {
      display: block;
      content: "";
      padding-bottom: 56.25%;
    }

    .home-slider {
      position: absolute;
      height: 100% !important;
      min-height: 100% !important;
      max-height: 100% !important;
      width: 100%;
      top: 0;
      bottom: 0;

      .home-slider_slide {
        height: 100% !important;
        max-height: 100% !important;
        min-height: 100% !important;
      }
    }


  }


  .home-slider {
    position: relative;
    height: 100%;

    &.arrows-off {
      .home-slider-prev {
        display: none;
      }

      .home-slider-next {
        display: none;
      }
    }

    // Overlay
    .home-slider-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      pointer-events: none;
    }

    // Arrows
    .home-slider-prev {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 70;
      border-radius: 100%;

      svg {
        transform: translateX(-1px);
      }
    }

    .home-slider-next {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 70;
      border-radius: 100%;

      svg {
        transform: translateX(1px);
      }
    }

    .home-slider-prev, .home-slider-next {
      svg {
        height: 20px;
        width: auto;
        display: block;
      }

      .arrow-wrap {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    // Pagination
    .swiper-pagination-bullets {
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 15px !important;
      left: 0 !important;
      right: 0 !important;
      top: auto !important;
      transform: none !important;

      .swiper-pagination-bullet {
        width: 12px;
        margin: 0 5px !important;
        height: 12px;
        transition: 0.3s all ease;
        opacity: 1;

        &:hover, &.swiper-pagination-bullet-active {
          opacity: 1;
        }
      }
    }

    // Slide
    &_slide {
      height: auto;
      overflow: hidden;
      position: relative;

      .slide-thumbnail {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        max-height: 100%;
        min-height: 100%;
        z-index: 0;

        .slide-bg {
          position: relative;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
        }

        .slide-video {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }
      }

      .iframe-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
        overflow: hidden;

        iframe {
          width: 100vw;
          height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
          min-height: 100vh;
          min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .slider-content {
        box-sizing: border-box;
        position: relative;
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        font-size: 17px;
        line-height: 24px;
        z-index: 10;
        padding: 60px 0;

        p {
          margin-bottom: 1em;
        }

        .slide-excerpt {
          margin-bottom: 10px;
          display: block;
          color: #fff;
        }

        h1 {
          display: block;
          color: #fff;
          font-weight: 500;
          font-size: 42px;
          margin-top: 0;
          padding-top: 0;
          margin-bottom: 25px;
        }

        .btn-wrap {
          margin-left: -8px;
          margin-right: -8px;
          display: flex;
          flex-wrap: wrap;

          align-items: center;

          .slider-btn {
            margin-left: 5px;
            margin-right: 5px;
            margin-bottom: 10px;
          }
        }

        &[data-horizontal-align='left'] {
          align-items: flex-start;
          text-align: left;

          .btn-wrap {
            justify-content: flex-start;
          }
        }

        &[data-horizontal-align='center'] {
          align-items: center;
          text-align: center;

          .btn-wrap {
            justify-content: center;
          }
        }

        &[data-horizontal-align='right'] {
          align-items: flex-end;
          text-align: right;

          .btn-wrap {
            justify-content: flex-end;
          }
        }


        &[data-vertical-align="top"] {
          justify-content: flex-start;
        }

        &[data-vertical-align="middle"] {
          justify-content: center;
        }

        &[data-vertical-align="bottom"] {
          justify-content: flex-end;
        }

      }

    }
  }

  @media (min-width: 1025px) {
    &.slider-long {
      max-height: 955px !important;
      height: 955px !important;

      .home-slider {
        &_slide {
          height: 955px !important;
          max-height: 955px !important;
        }
      }
    }
    &.slider-short {
      max-height: 655px !important;
      height: 655px !important;

      .home-slider {
        &_slide {
          height: 655px !important;
          max-height: 655px !important;
        }
      }
    }
  }
  @media (max-width: 1024px) {

    max-height: 600px;
    height: 600px;

    .home-slider {
      height: 600px;
      max-height: 600px;
    }
  }
  @media (max-width: 768px) {
    &[data-mobile="off"] {
      display: none;
    }
    .home-slider {
      &_slide {
        .slider-content {
          max-width: calc(100% - 140px);
          margin-left: auto;
          margin-right: auto;
          font-size: 11px;
          line-height: 14px;
          padding: 90px 0;

          h1 {
            font-size: 28px;
          }
        }
      }
    }
  }
  @media (max-width: 720px) {
    min-height: 500px;
    height: 500px;

    .home-slider {
      min-height: 500px;
      height: 500px;

      .home-slider-prev {
        left: 8px;
      }

      .home-slider-next {
        right: 8px;
      }

      &_slide {
        .slider-content {
          h1 {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
  @media (max-width: 660px) {
    .home-slider {
      &_slide {
        .slider-content {
          padding: 30px 0;
          max-width: calc(100% - 105px);

          .slide-excerpt {
            font-size: 14px;

            P {
              font-size: 15px;
            }
          }

          .btn-wrap {
            .slider-btn {
              font-size: 14px !important;
              padding: 12px !important;
            }
          }
        }
      }

      &.arrows-off {
        .home-slider_slide {
          .slider-content {
            max-width: calc(100% - 60px);
          }
        }
      }
    }
  }
}


// D Header Style
.nav_style_d {
  .home-slider-wrapper {
    .home-slider_slide {
      .slider-content {
        display: none;
      }
    }
  }
}


// Transparent Nav
@media (min-width: 1025px) {
  .a_e_nav_transparent_on.page-template-home-page {
    padding-top: 0 !important;

    &.admin-bar {
      padding-top: calc(32px) !important;
    }

    &.top_bar_desktop_on {
      padding-top: calc(56px) !important;
    }

    &.admin-bar.top_bar_desktop_on {
      padding-top: calc(56px + 32px) !important;
    }

    .home-slider-wrapper {

      .home-slider_slide .slider-content {
        padding-top: 160px;
      }


      &.slider-long {
        max-height: 1055px !important;
        height: 1055px !important;

        .home-slider {
          &_slide {
            height: 1055px !important;
            max-height: 1055px !important;
          }
        }
      }

      &.slider-short {
        max-height: 755px !important;
        height: 755px !important;

        .home-slider {
          &_slide {
            height: 755px !important;
            max-height: 755px !important;
          }
        }
      }
    }
  }
}