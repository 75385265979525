// Basic
.clearfix {
  &:before, &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

.aligncenter, .center {
  text-align: center;
}

.alignleft, .left {
  text-align: left;
}

.alignright, .right {
  text-align: right;
}

.mb-no {
  margin-bottom: 0 !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}


.no-mt {
  margin-top: 0 !important;
}


#lasso--save {
  margin-right: 100px !important;
}

body .wpforms-form button[type=submit] {
  font-size: 18px !important;
}

.wpforms-submit:active, .wpforms-submit:focus, .wpforms-submit:hover, div.wpforms-container-full .wpforms-form button[type=submit]:active, div.wpforms-container-full .wpforms-form button[type=submit]:hover {
  border: 1px solid !important;
}

.btn, .btn:hover {
  border: 2px solid !important;
}

.home a.wpforms-submit.slider-btn:hover {
  border: 2px solid !important;
}

.wpforms-container input[type=checkbox] {
  -webkit-appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  appearance: checkbox !important;
}

.wpforms-container input[type=radio] {
  -webkit-appearance: radio !important;
  -moz-appearance: radio !important;
  appearance: radio !important;
}